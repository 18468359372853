import Image from 'next/image'
import Carousel, { ResponsiveType } from 'react-multi-carousel'
import { motion, useReducedMotion } from 'framer-motion'
import { useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'
import { childrenVariants } from '@Config/framer/variants'
import { transitionChildren } from '@Config/framer/transitions'
import { IgPhotos } from '@Types/props/ig-photos'
import styles from './image.module.scss'

export interface IgSlider {
  igImages: IgPhotos
}

const responsive: ResponsiveType = {
  desktop: {
    breakpoint: { max: 3840, min: 1420 },
    items: 6,
    slidesToSlide: 6,
  },
  tablet: {
    breakpoint: { max: 1420, min: 720 },
    items: 3,
    slidesToSlide: 3,
  },
  mobile: {
    breakpoint: { max: 720, min: 0 },
    items: 2,
    slidesToSlide: 2,
  },
}

const Root = styled(motion.section)(({ theme }) => ({
  ...theme.mixins.container,
  maxWidth: '1980px',
  padding: theme.spacing(3, 0),
  zIndex: 1,
  marginTop: theme.spacing(2),
}))

export function IgSlider({ igImages }: IgSlider): JSX.Element | null {
  const isSmallScreen = useMediaQuery(
    '@media screen and (max-width: 1024px)',
  )
  const isReducedMotion = useReducedMotion()

  //todo videos
  return (
    <Root variants={childrenVariants} transition={transitionChildren}>
      <Carousel
        draggable={false}
        swipeable={isSmallScreen}
        autoPlay
        pauseOnHover
        ssr
        infinite
        arrows={false}
        responsive={responsive}
        autoPlaySpeed={7000}
        centerMode
        transitionDuration={isReducedMotion ? 0 : 400}
        customTransition={
          isReducedMotion ? 'all 0s' : 'transform 400ms ease-in-out'
        }
      >
        {igImages.map((img) => {
          const isImg =
            Array.isArray(img.image_versions2?.candidates) &&
            !!img.image_versions2?.candidates[0]

          if (!isImg) {
            console.log('not image', img)
            return null
          }

          return (
            <a
              href={`https://instagram.com/p/${img.code}`}
              target={'_blank'}
              rel={'noreferrer nofollow'}
              className={styles.image}
              key={img.id}
            >
              <Image
                layout={'intrinsic'}
                alt={img.caption?.text}
                objectFit={'cover'}
                height={300}
                width={300}
                src={img.image_versions2.candidates[0].url}
                className={styles.igImage}
                loading={'lazy'}
              />
            </a>
          )
        })}
      </Carousel>
    </Root>
  )
}
