import { MetaTag } from 'next-seo/lib/types'
import { LogoJsonLd, LocalBusinessJsonLd, NextSeo } from 'next-seo'
import { SITE_SETTINGS_SiteConfig } from '@GraphQL/modules/global/__generated__/SITE_SETTINGS'
import { HOME_Page_openGraphImage } from '@GraphQL/modules/pages/__generated__/HOME'
import { images } from '@Config/pages/home'
import openGraphImage from '@Static/images/1-slider.jpg'
import logoSrc from '@Static/images/real-logo.png'

interface Head {
  siteConfig: SITE_SETTINGS_SiteConfig | null | undefined
  pageConfig: {
    title: string
    description: string
    openGraphImage?:
      | Partial<HOME_Page_openGraphImage>
      | null
      | undefined
  }
  additionalMetaTags?: ReadonlyArray<MetaTag>
}

const currentDate = new Date()
const expDate = new Date(
  currentDate.getFullYear() + 1,
  currentDate.getMonth(),
  currentDate.getDate(),
)

/**
 * includes any dynamic (page-dependent) and static (business info) seo
 */
export function Head({ pageConfig, additionalMetaTags }: Head) {
  //todo ArtGallery types
  return (
    <>
      <NextSeo
        description={pageConfig.description}
        title={pageConfig.title}
        openGraph={{
          type: 'WebPage',
          description: pageConfig.description,
          title: `Z Co-space & Clubhouse: ${pageConfig.title}`,
          images: [
            {
              url:
                pageConfig?.openGraphImage?.asset?.url ??
                openGraphImage.src,
            },
          ],
        }}
        additionalMetaTags={additionalMetaTags}
      />
      <LogoJsonLd
        logo={logoSrc.src}
        url={'https://zcospaceclubhouse.com'}
      />
      <LocalBusinessJsonLd
        type={'CafeOrCoffeeShop'}
        id={'https://www.zcospaceclubhouse.com'}
        description={
          'A space to immerse yourself in an oasis blend of business and pleasure. A coworking space and studio during the day and in the evening an entertainment venue. Indulge in our handcrafted mocktails meticulously crafted by our skilled mixologists, while'
        }
        name={'Z Co-space & Clubhouse'}
        address={{
          streetAddress: '1413 Haven Dr',
          addressLocality: 'Orlando',
          addressRegion: 'FL',
          addressCountry: 'USA',
          postalCode: '32803',
        }}
        telephone={'+14079868260'}
        openingHours={[
          {
            opens: '9:00',
            closes: '00:00',
            dayOfWeek: [
              'Monday',
              'Tuesday',
              'Wednesday',
              'Thursday',
              'Friday',
            ],
            validFrom: currentDate.toLocaleDateString(),
            validThrough: expDate.toLocaleDateString(),
          },
          {
            opens: '10:00',
            closes: '00:00',
            dayOfWeek: ['Saturday'],
            validFrom: currentDate.toLocaleDateString(),
            validThrough: expDate.toLocaleDateString(),
          },
        ]}
        images={[
          ...images.map((img) => img.src.src),
          `https://www.zcospaceclubhouse.com${logoSrc.src}`,
          `https://www.zcospaceclubhouse.com${openGraphImage.src}`,
        ]}
        sameAs={[
          'https://www.instagram.com/zcospaceclubhouse/',
          'https://www.facebook.com/zcospaceclubhouse',
        ]}
        priceRange={'$$'}
        geo={{
          latitude: '28.5645971',
          longitude: '-81.3660057',
        }}
      />
    </>
  )
}
