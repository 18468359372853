import Carousel, { ResponsiveType } from 'react-multi-carousel'
import Image from 'next/image'
import { useMediaQuery } from '@mui/material'

import styles from './image.module.scss'

const responsive: ResponsiveType = {
  desktop: {
    breakpoint: { max: 3840, min: 0 },
    items: 1,
  },
}

export function Slider({ images }: { images: Array<any> }) {
  const isSmallScreen = useMediaQuery(
    '@media screen and (max-width: 1024px)',
  )
  // const isReducedMotion = useReducedMotion()

  return (
    <Carousel
      draggable={false}
      swipeable={isSmallScreen}
      autoPlay={false}
      ssr
      infinite={false}
      arrows={false}
      responsive={responsive}
    >
      {images.map((image, index) => (
        <div className={styles.image} key={index}>
          <Image
            src={image.src}
            alt={image.alt}
            width={1200}
            height={800}
            layout={'responsive'}
            objectFit={'cover'}
            quality={95}
            placeholder={'blur'}
            objectPosition={image.objectPosition}
          />
        </div>
      ))}
    </Carousel>
  )
}
