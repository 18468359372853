import { motion } from 'framer-motion'
import { styled } from '@mui/material/styles'
import { Link, Typography } from '@mui/material'

import { getIg } from '@Lib/ig'
import { getStaticPropsWrap } from '@Lib/apollo/get-props'
import { homeQuery } from '@GraphQL/modules/pages/home'
import { siteSettings } from '@GraphQL/modules/global/site-settings'
import {
  HOME,
  HOME_Page,
} from '@GraphQL/modules/pages/__generated__/HOME'
import {
  SITE_SETTINGS,
  SITE_SETTINGS_SiteConfig,
} from '@GraphQL/modules/global/__generated__/SITE_SETTINGS'
import { Head } from '@Components/elements/SEO/head'
import { Slider } from '@Components/elements/Slider'
import { IgSlider } from '@Components/elements/IgSlider'
import { childrenVariants } from '@Config/framer/variants'
import {
  transitionChildren,
  transitionChildrenFast,
} from '@Config/framer/transitions'
import { IgPhotos } from '@Types/props/ig-photos'
import React from 'react'
import { images } from '@Config/pages/home'

const PREFIX = 'Homepage'

const classes = {
  section: `${PREFIX}-section`,
  contentWrap: `${PREFIX}-contentWrap`,
  innerWrap: `${PREFIX}-innerWrap`,
  titleContent: `${PREFIX}-titleContent`,
  content: `${PREFIX}-content`,
  text: `${PREFIX}-text`,
  subtitle: `${PREFIX}-subtitle`,
  subtitle2: `${PREFIX}-subtitle2`,
  title: `${PREFIX}-title`,
}

interface HomepageProps {
  pageData: HOME_Page | null
  seoData: SITE_SETTINGS_SiteConfig | null
  igImages: IgPhotos | null
}

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.section}`]: {
    ...theme.mixins.container,
    padding: theme.spacing(1, 0, 3),
    zIndex: 1,
    marginTop: theme.spacing(2),
  },

  [`& .${classes.contentWrap}`]: {
    ...theme.mixins.container,
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(2, 1.5),
    zIndex: 1,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },

  // the title content on the left
  [`& .${classes.titleContent}`]: {
    flexBasis: '50%',
    width: 'auto',
    position: 'relative',
    display: 'block',
    paddingTop: theme.spacing(1.5),
    alignSelf: 'center',
    // alignSelf: 'center',
    [theme.breakpoints.down('md')]: {
      flexBasis: '100%',
    },
  },

  // the main stuff on right
  [`& .${classes.content}`]: {
    width: 'auto',
    flexBasis: '50%',
    position: 'relative',
    display: 'block',
    paddingTop: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: {
      flexBasis: '100%',
      padding: 0,
    },
  },

  // inner wrap for the contents
  [`& .${classes.innerWrap}`]: {
    display: 'block',
    position: 'relative',
    height: 'auto',
    padding: theme.spacing(0, 1.5, 1),
    margin: 0,
  },
  [`& .${classes.text}`]: {
    ...theme.mixins.perfectText,
    maxWidth: '120ch',
    margin: '0 0 0.35em',
    lineHeight: 1.5,
  },

  [`& .${classes.subtitle}`]: {
    // ...theme.mixins.perfectText,
    // maxWidth: '120ch',
    lineHeight: 1.3,
    margin: theme.spacing(3, 0, 1),
    display: 'inline-block',
    ...theme.mixins.menuTitleFont,
  },

  [`& .${classes.subtitle2}`]: {
    // ...theme.mixins.perfectText,
    // maxWidth: '120ch',
    lineHeight: 1.3,
    margin: '0 0 0.35em',
    display: 'inline-block',
  },

  [`& .${classes.title}`]: {
    // letterSpacing: 2.4,
    // fontVariant: 'all-petite-caps',
    // fontSize: '2.9rem',
    // display: 'inline-block',
    // position: 'relative',
    // lineHeight: 1.3,
    // [theme.breakpoints.down('md')]: {
    //   letterSpacing: 2.1,
    //   fontSize: '1.9rem',
    // },
    ...theme.mixins.visuallyHidden,
  },
}))

function Homepage({ seoData, igImages }: HomepageProps) {
  return (
    <Root>
      <Head
        siteConfig={seoData}
        pageConfig={{
          title: 'A space to immerse yourself',
          description:
            'A space to immerse yourself in an oasis blend of business and pleasure. A coworking space and studio during the day and in the evening an entertainment venue. Indulge in our handcrafted mocktails meticulously crafted by our skilled mixologists, while',
        }}
        additionalMetaTags={[
          {
            name: 'keywords',
            content:
              'kava bar, orlando kava lounge, kava, kombucha, coffee, espresso, cospace, co-space, clubhouse',
          },
        ]}
      />
      <motion.section
        variants={childrenVariants}
        transition={transitionChildrenFast}
        className={classes.section}
      >
        <Slider images={images} />
      </motion.section>
      <section className={classes.contentWrap}>
        <div className={classes.titleContent}>
          <div className={classes.innerWrap}>
            <Typography
              align={'center'}
              component={motion.h1}
              variants={childrenVariants}
              transition={transitionChildren}
              gutterBottom
              variant={'h2'}
              className={classes.title}
              color={'text.secondary'}
            >
              A space to immerse yourself
            </Typography>
            <Typography
              align='center'
              component={motion.p}
              variants={childrenVariants}
              transition={transitionChildren}
              gutterBottom
              variant={'h5'}
              className={classes.subtitle2}
              fontStyle={'italic'}
              color={'text.secondary'}
            >
              A space to immerse yourself in an oasis blend of
              business and pleasure. A coworking space and studio
              during the day and in the evening an entertainment
              venue. Creating a diverse and dynamic community of
              like-minded individuals with the collective goal to
              evolve.
            </Typography>
          </div>
        </div>
        <div className={classes.content}>
          <div className={classes.innerWrap}>
            <Typography
              component={motion.h2}
              variants={childrenVariants}
              transition={transitionChildren}
              variant={'h5'}
              fontWeight={'lighter'}
              className={classes.subtitle}
            >
              Orlando’s hottest new Social Club. Welcome to Z Co-Space
              and Clubhouse, the ultimate social club experience in
              the heart of Central Florida.
            </Typography>
            <Typography
              component={motion.p}
              variants={childrenVariants}
              transition={transitionChildren}
              gutterBottom
              variant={'body1'}
              className={classes.text}
            >
              Nestled amidst the vibrant energy of Orlando, our
              exclusive club is a haven for those seeking a unique and
              exciting social scene. With a fusion of luxury and
              cutting-edge design, Z Co-Space and Clubhouse offers a
              captivating ambiance that sets it apart from the rest.
              Step inside and immerse yourself in an oasis blend of
              business and pleasure. A co-working space and studio
              during the day and in the evening an entertainment
              venue. Indulge in our handcrafted mocktails meticulously
              crafted by our skilled mixologists, while mingling with
              a diverse and dynamic community of like-minded
              individuals.
            </Typography>
            <Typography
              component={motion.p}
              variants={childrenVariants}
              transition={transitionChildren}
              gutterBottom
              variant={'body1'}
              className={classes.text}
            >
              Whether you&#39;re looking to network, unwind, or simply
              have a memorable night out, Z Co-Space and Clubhouse is
              your passport to an unforgettable social experience,
              where the city&#39;s elite come together to revel in the
              magic of conversation.
            </Typography>
            <Typography
              component={motion.p}
              variants={childrenVariants}
              transition={transitionChildren}
              gutterBottom
              variant={'body1'}
              className={classes.text}
            >
              Visit us at{' '}
              <Link
                href={'https://goo.gl/maps/om1cUZ55uMP6kmSaA'}
                target={'_blank'}
                rel={'noreferrer'}
                underline={'hover'}
                color={'secondary'}
              >
                1413 Haven Dr. Orlando, FL 32803 Mills 50 District
              </Link>
            </Typography>
          </div>
        </div>
      </section>
      {Array.isArray(igImages) && <IgSlider igImages={igImages} />}
    </Root>
  )
}

export const getStaticProps = getStaticPropsWrap<HomepageProps>(
  async ({ apollo }) => {
    try {
      const { data } = await apollo.query<HOME>({ query: homeQuery })
      const { data: seoData } = await apollo.query<SITE_SETTINGS>({
        query: siteSettings,
      })
      const igImages = await getIg()
      return {
        props: {
          pageData: data?.Page ?? null,
          seoData: seoData?.SiteConfig ?? null,
          igImages: igImages ?? null,
        },
        // revalidate every 24 hours
        revalidate: 86400,
      }
    } catch (e) {
      console.error(e)
      return {
        props: {
          seoData: null,
          pageData: null,
          igImages: null,
        },
      }
    }
  },
)

export default Homepage
